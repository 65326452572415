
export interface DiverCertification {
    certBody: string;
    certLevel: string;
    certLocation: string;
}

export interface UserInterface {
    uid: string;
    accepted: boolean;
    username: string;
    coverUrl: string;
    firstName: string;
    lastName: string;
    photoUrl: string;
    photoHash: string;
    currentLocation: string;
    homeCountryCode: string;
    certBody: string;
    certLevel: string;
    numberOfDives: string;
    gender: null | "male" | "female";
    birthday: string;
    joined: string | null;
    bio: string;
    interested: { [key: string]: boolean };
    certifications: DiverCertification[];
    disableWebIndexing: boolean;

}

export const scoreProps = ["marine-life", "professionalism", "sustainability", "value"] as const;

export type ScoreProp = typeof scoreProps[number]

export type ScoreCard = Record<ScoreProp, number>;

export interface ReviewInterface {
    id: string
    verified: boolean
    body: string
    timeAdded: string,
    scores: ScoreCard,
    translations: {
        "en": string
        "fr": string
        "es": string
    },
    "detectedLang": string,
    "detectedLangName": string,
    by: UserInterface
}

export interface SlimUserInterface {
    username?: string
    photoUrl?: string
    homeCountryCode?: string
    accepted?: boolean
}



export interface TripDb {
    [tripId: string]: TripDbRecord
}

export interface TripDbRecord {
    firstImageBlurDataURL: string;
    slugs: {
        [langKey: string]: {
            countrySlug: string;
            tripSlug: string;
        }
    }
    offering: {
        [langKey: string]: {
            offer: ""
        }
    }
    offerLowUSD: number,
    offerHighUSD: number
}

